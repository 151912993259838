/* src/components/Navbar.css */

.navbar {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1000;
  padding: 15px 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.logo {
  font-size: 26px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
  color: #002b36;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.navbar-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}

/* Bütün düymə stili */
.login-btn,
.post-btn,
.moto-btn,
.contact-btn,
.about-btn,
.whatsapp-btn {
  border: none;
  border-radius: 12px;
  padding: 10px 16px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  transition: background 0.3s ease;
}

.login-btn {
  background-color: #f6f6f6;
}

.post-btn {
  background-color: #ff2b74;
  color: white;
  font-weight: bold;
}

.moto-btn {
  background-color: #0066ff;
  color: white;
  font-weight: bold;
}

.contact-btn {
  background-color: #fffbe6;
  color: #333;
  border: 1px solid #ffd966;
}

.about-btn {
  background-color: #f0f0f0;
  color: #444;
}

.whatsapp-btn {
  background-color: #25D366;
  color: white;
  font-weight: bold;
}

/* Hover efektləri */
.login-btn:hover,
.contact-btn:hover,
.about-btn:hover {
  background-color: #eaeaea;
}

.post-btn:hover {
  background-color: #e91e63;
}

.moto-btn:hover {
  background-color: #004cc4;
}

.whatsapp-btn:hover {
  background-color: #1ebe5b;
}


/* ========================== 📱 MOBİL STİL ========================== */
@media (max-width: 768px) {
  .navbar-content {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .navbar-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    flex-wrap: nowrap;
  }

  /* Yalnız contact, about, whatsapp düymələrini göstər */
  .login-btn,
  .post-btn,
  .moto-btn {
    display: none;
  }

  .contact-btn,
  .about-btn,
  .whatsapp-btn {
    display: inline-block;
    padding: 8px 14px;
    font-size: 13px;
  }

  .logo {
    font-size: 20px;
    margin-bottom: 6px;
  }
}
