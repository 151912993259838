.users-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .back-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 18px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  
  .users-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 25px;
    text-align: center;
    color: #222;
  }
  
  .search-input {
    width: 100%;
    max-width: 400px;
    padding: 12px 16px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 0 auto 30px auto;
    display: block;
    box-sizing: border-box; /* ← bu çox vacibdir */
    transition: border-color 0.2s ease;
  }
  
  
  .search-input:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .users-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 24px;
  }
  
  .user-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #eee;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .user-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
  
  .user-card h3 {
    margin: 0 0 12px;
    font-size: 20px;
    color: #333;
  }
  
  .user-card p {
    margin: 6px 0;
    font-size: 15px;
    color: #555;
    line-height: 1.4;
  }
  
  .card-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  
  .edit-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 8px 14px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .edit-button:hover {
    background-color: #218838;
  }
  
  .block-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 8px 14px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .block-button:hover {
    background-color: #c82333;
  }
  
  .no-users {
    text-align: center;
    color: #999;
    font-style: italic;
    margin-top: 40px;
    font-size: 16px;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px; /* Telefon ekranlarında boşluq üçün */
    box-sizing: border-box;
    z-index: 1000;
  }
  
  
  .modal {
    background-color: #fff;
    padding: 24px;
    border-radius: 12px;
    width: 100%;
    max-width: 420px; /* Maksimum eni qoruyur */
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 8px;
    animation: fadeIn 0.3s ease-in-out;
    box-sizing: border-box;
  }
  
  
  .modal h3 {
    margin: 0 0 10px;
    text-align: center;
    color: #333;
    font-size: 20px;
  }
  
  .modal label {
    font-weight: 600;
    margin-bottom: 4px;
    margin-top: 10px;
    display: block;
    color: #333;
  }
  
  .modal input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 15px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 16px;
  }
  
  .save-button,
  .cancel-button {
    flex: 1;
    padding: 10px 16px;
    font-size: 15px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .save-button {
    background-color: #28a745;
  }
  
  .save-button:hover {
    background-color: #218838;
  }
  
  .cancel-button {
    background-color: #dc3545;
  }
  
  .cancel-button:hover {
    background-color: #c82333;
  }
  
  /* Responsive (məsələn iPhone SE üçün) */
  @media (max-width: 480px) {
    .modal {
      padding: 16px;
    }
  
    .modal h3 {
      font-size: 18px;
    }
  
    .modal-actions {
      flex-direction: column;
    }
  
    .save-button,
    .cancel-button {
      width: 100%;
    }
  }
  
  /* Fade in animasiya */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  