.moto-taksi-sifarish-container {
    min-height: 100vh;
    background-color: #f3f4f6;
    padding: 20px;
}

.moto-taksi-sifarish-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #1f2937;
    margin-bottom: 24px;
}

.moto-taksi-sifarish-grid {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;
}

@media (min-width: 768px) {
    .moto-taksi-sifarish-grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 1024px) {
    .moto-taksi-sifarish-grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.moto-taksi-sifarish-card {
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    padding: 16px;
    transition: box-shadow 0.3s ease;
}

.moto-taksi-sifarish-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.moto-taksi-sifarish-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.moto-taksi-sifarish-card-id {
    font-size: 14px;
    color: #4b5563;
}

.moto-taksi-sifarish-card-id span {
    color: #2563eb;
    font-weight: 500;
}

.moto-taksi-sifarish-status {
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 9999px;
}

.moto-taksi-sifarish-status.completed {
    background-color: #d1fae5;
    color: #059669;
}

.moto-taksi-sifarish-status.pending {
    background-color: #fef3c7;
    color: #d97706;
}

.moto-taksi-sifarish-text-block {
    font-size: 14px;
    color: #374151;
    margin-bottom: 8px;
}

.moto-taksi-sifarish-text-block strong {
    color: #1f2937;
}

.moto-taksi-sifarish-timestamp {
    font-size: 12px;
    text-align: right;
    color: #9ca3af;
}


.clickable-username {
    color: #2563eb;
    cursor: pointer;
    font-weight: 500;
    transition: color 0.2s ease;
}

.clickable-username:hover {
    color: #1d4ed8;
    text-decoration: underline;
}

.no-orders-message {
    font-size: 16px;
    color: #6b7280;
    text-align: center;
    padding: 40px 0;
  }
  .moto-taksi-sifarish-actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .edit-btn,
  .delete-btn {
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    transition: 0.2s ease;
  }
  
  .edit-btn {
    background-color: #facc15;
    color: #1f2937;
  }
  
  .edit-btn:hover {
    background-color: #eab308;
  }
  
  .delete-btn {
    background-color: #f87171;
    color: white;
  }
  
  .delete-btn:hover {
    background-color: #ef4444;
  }

  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.moto-taksi-sifarish-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  padding: 16px; /* Mobil görünüş üçün boşluq */
  animation: fadeIn 0.3s ease-in-out;
}

.moto-taksi-sifarish-modal {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 16px;
  width: 100%;
  max-width: 420px;
  font-family: 'Inter', sans-serif;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.15);
  animation: slideUp 0.3s ease-in-out;
  box-sizing: border-box;
}

.moto-taksi-sifarish-modal h3 {
  margin-top: 0;
  font-size: 20px;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 12px;
  text-align: center;
}

.moto-taksi-sifarish-textarea {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  border: 1px solid #d1d5db;
  border-radius: 10px;
  resize: vertical;
  transition: border 0.2s ease;
  box-sizing: border-box;
}

.moto-taksi-sifarish-textarea:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
}

.moto-taksi-sifarish-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
  flex-wrap: wrap;
}

/* Tamamla düyməsi */
.complete-btn {
  background-color: #28a745;
  color: white;
}

.save-btn,
.complete-btn,
.cancel-btn {
  padding: 8px 18px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  transition: background-color 0.2s ease;
  font-family: 'Inter', sans-serif;
  min-width: 100px;
  text-align: center;
}

/* Hover effektləri */
.save-btn:hover,
.complete-btn:hover,
.cancel-btn:hover {
  background-color: #218838; /* Tünd yaşıl rəng hover effekti */
}

.cancel-btn:hover {
  background-color: #c82333; /* Tünd qırmızı rəng hover effekti */
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0 }
  to { opacity: 1 }
}

@keyframes slideUp {
  from { transform: translateY(30px); opacity: 0 }
  to { transform: translateY(0); opacity: 1 }
}

/* Mobil üçün xüsusi düzəlişlər */
@media (max-width: 480px) {
  .moto-taksi-sifarish-modal {
    padding: 20px 16px;
    border-radius: 12px;
  }

  .moto-taksi-sifarish-modal-actions {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .save-btn,
  .cancel-btn {
    width: 100%;
  }
}