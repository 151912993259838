/* Profile.css */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Ortalayır */
  gap: 30px;
  padding: 40px 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f0f2f5;
  min-height: 100vh;
}

.profile-card {
  background-color: #ffffff;
  padding: 25px 20px;
  /* sağ və sol padding verildi */
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  max-width: 420px;
  width: 100%;
  text-align: center;
  transition: transform 0.3s ease;
  box-sizing: border-box;
  /* padding ölçünü daşırmasın deyə */
}


.profile-card:hover {
  transform: scale(1.02);
}

.profile-avatar {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
  border: 3px solid #4CAF50;
}

.profile-name {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.profile-phone,
.profile-email {
  margin: 6px 0;
  color: #666;
  font-size: 14px;
}

.profile-actions {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 18px;
}

.edit-btn,
.logout-btn {
  padding: 9px 14px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.edit-btn {
  background-color: #4CAF50;
  color: white;
}

.logout-btn {
  background-color: #f44336;
  color: white;
}

.password-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.password-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
}

.save-btn {
  background-color: #2196F3;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.my-elans-section {
  background-color: #ffffff;
  padding: 25px 20px;
  /* sağ və sol padding verildi */
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 900px;
}

.my-elans-section h3 {
  margin-top: 0;
  font-size: 20px;
  color: #333;
}

.category-filter {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.category-filter button {
  padding: 7px 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #eee;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.category-filter button:hover {
  background-color: #ddd;
  transform: translateY(-1px);
}

.elans-list-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  padding-bottom: 50px;
  padding-top: 10px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}

.elans-list-cards>* {
  flex: 1 1 calc(50.00% - 18px);
  box-sizing: border-box;
}

.my-sifarishler-section {
  margin-top: 40px;
  padding: 20px;
  background-color: #f9fafb;
  border-radius: 12px;
}

.sifarish-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sifarish-card {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.sifarish-time {
  font-size: 13px;
  color: #6b7280;
}

.sifarish-status {
  margin-top: 5px;
  font-weight: 600;
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 6px;
  display: inline-block;
}

.sifarish-status.done {
  color: #15803d;
  background-color: #dcfce7;
}

.sifarish-status.pending {
  color: #92400e;
  background-color: #fef3c7;
}

.sifarish-actions {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.status-btn, .delete-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
}

.status-btn {
  background-color: #38bdf8;
  color: white;
}

.status-btn:hover {
  background-color: #0ea5e9;
}

.delete-btn {
  background-color: #f87171;
  color: white;
}

.delete-btn:hover {
  background-color: #ef4444;
}

/* Notification Modal */
.notification-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker background for better contrast */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeIn 0.3s ease-in-out;
}

.notification-content {
  background-color: #fff;
  padding: 10px;
  border-radius: 12px;
  max-width: 500px;
  width: 80%; /* Ensures the modal width is responsive */
  text-align: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  animation: slideUp 0.5s ease-in-out;
}

.notification-content h4 {
  font-size: 20px; /* Larger font for the heading */
  font-weight: 700;
  margin-bottom: 20px;
  color: #e11d48; /* Red color for emphasis */
}

.notification-content p {
  font-size: 18px;
  color: #4b5563;
  margin-bottom: 20px;
  font-weight: 500;
}

.close-btn {
  padding: 10px 20px;
  background-color: #22c55e; /* Green for positive action */
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.close-btn:hover {
  background-color: #16a34a; /* Darker green on hover */
}

/* Animation for smooth modal appearance */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .notification-content {
    padding: 20px;
    width: 80%; /* Adjusts modal width for smaller screens */
  }

  .notification-content h4 {
    font-size: 22px; /* Smaller heading size for mobile */
  }

  .notification-content p {
    font-size: 16px; /* Smaller text for mobile */
  }

  .close-btn {
    font-size: 14px; /* Adjust button text size */
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .notification-content {
    padding: 15px;
    width: 80%; /* Even smaller width for very small screens */
  }

  .notification-content h4 {
    font-size: 20px; /* Smaller heading for very small screens */
  }

  .notification-content p {
    font-size: 14px; /* Smaller text for very small screens */
  }

  .close-btn {
    font-size: 12px; /* Even smaller button text */
    padding: 6px 14px;
  }
}
