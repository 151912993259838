.footer {
  background-color: #ffffff;
  padding-top: 50px;
  font-family: 'Segoe UI', sans-serif;
}

.footer-desktop-nav {
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 30px;
  font-size: 14px;
  color: #777;
  background-color: #ffffff;
}

.footer-desktop-nav .footer-left {
  font-weight: 600;
  color: #333;
}

.footer-desktop-nav .footer-right {
  display: flex;
  gap: 20px;
}

.footer-desktop-nav .footer-link {
  color: #777;
  text-decoration: none;
  transition: color 0.2s ease;
}

.footer-desktop-nav .footer-link:hover {
  color: #e53935;
}


.footer-mobile-nav {
  display: none;
}

/* Mobil görünüşü */
@media (max-width: 768px) {
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background: #ffffff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px 20px 0 0;
    padding: 20px 0;
  }

  .footer-desktop-nav {
    display: none;
  }

  .footer-mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 10px;
  }

  .footer-icon {
    flex: 1;
    text-align: center;
    color: #a0a0a0;
    transition: all 0.2s ease;
  }

  .footer-icon svg {
    display: block;
    margin: 0 auto 4px auto;
    font-size: 25px;
  }

  .footer-icon span {
    display: block;
    font-size: 12px;
    font-weight: 400;
  }

  .footer-icon.active {
    color: #e53935;
  }

  .footer-icon.active span {
    font-weight: 600;
  }

  .footer-icon:hover {
    color: #27ae60;
  }
}
