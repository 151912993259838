
  
  .elan-container h2 {
    text-align: center;
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .elan-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .elan-form input[type="text"],
  .elan-form input[type="number"],
  .elan-form textarea,
  .elan-form select {
    padding: 10px 14px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: border-color 0.2s;
  }
  
  .elan-form input:focus,
  .elan-form textarea:focus,
  .elan-form select:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .elan-form textarea {
    resize: vertical;
    min-height: 120px;
  }
  
  .file-label {
    background-color: #f0f0f0;
    padding: 10px 14px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    font-weight: 500;
  }
  
  .image-preview {
    text-align: center;
  }
  
  .image-preview img {
    max-width: 100%;
    max-height: 250px;
    border-radius: 8px;
    margin-top: 10px;
  }
  
  .elan-form button {
    background-color: #28a745;
    color: white;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .elan-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .elan-form button:hover:not(:disabled) {
    background-color: #218838;
  }
  
  .elan-form label {
    font-weight: 600;
    margin-bottom: 6px;
    display: block;
    color: #333;
  }

