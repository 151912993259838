.auth-container {
  width: 100vw;
  background: white;
  display: flex;
  padding-top: 50px;
  justify-content: center;
  align-items: center;
  font-family: 'Segoe UI', sans-serif;
  box-sizing: border-box;
}

.auth-box {
  width: 400px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 40px 30px;
}

.auth-tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.auth-tabs button {
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  padding-bottom: 5px;
  transition: 0.3s;
}

.auth-tabs button.active {
  color: #27ae60;
  border-color: #27ae60;
}

.auth-desc {
  font-size: 14px;
  margin-bottom: 20px;
  color: #555;
  text-align: center;
}

.auth-right input[type="email"],
.auth-right input[type="password"],
.auth-right input[type="text"] {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  box-sizing: border-box;
}

.auth-remember {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  margin-bottom: 20px;
}

.auth-submit {
  width: 100%;
  padding: 12px;
  background-color: #27ae60;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 12px;
  font-size: 15px;
  transition: background 0.3s;
}

.auth-submit:hover {
  background-color: #219150;
}

.auth-reset {
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  margin-bottom: 15px;
  cursor: pointer;
}

@media (max-width: 480px) {
  .auth-box {
    width: 90%;
    padding: 30px 20px;
  }

  .auth-tabs button {
    font-size: 14px;
  }
}
