.ana-container {
  padding: 10px;
  max-width: 1200px;
  margin: 0 auto;
}

.category-container {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  justify-content: center;
  flex-wrap: wrap;
}

.category-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100px;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease;
}

.category-card:hover {
  transform: scale(1.05);
}

.category-card img {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
}

.category-card span {
  margin-top: 12px;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
}

.section-title {
  padding-left: 10px;
  font-size: 24px;
  font-weight: bold;
}

.elans-container {
  display: flex; /* Flex konteyneri yaradır */
  flex-wrap: wrap; /* Sətir boyunca elementlərin sıxılmasını təmin edir */
  gap: 18px; /* Elementlər arasında boşluq */
  padding-top: 10px;
  justify-content: center; /* Elementləri üfüqi mərkəzləşdirir */
  align-items: flex-start; /* Yuxarıdan başlayaraq sıralanır */
  align-content: flex-start; /* Hər bir sətir üçün sıralanmanı tənzimləyir */
}

.elans-container > * {
  flex: 1 1 calc(50.00% - 18px); /* Hər bir elementin eni 3 sütunlu düzən üçün uyğunlaşdırılır */
  box-sizing: border-box; /* Elementin ölçüsünü təyin edərkən padding daxil edilir */
}


/* WhatsApp float button */
.whatsapp-float {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
}

.whatsapp-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
}

.whatsapp-button {
  background-color: #25D366;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.2);
  color: white;
  font-size: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
}

.whatsapp-button:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .whatsapp-float {
    display: none;
  }
}


.load-more {
  display: block;
  width: auto; /* Öz ölçüsündə */
  padding: 12px 20px; /* İstədiyiniz ölçüdə */
  background-color: #fff; /* Arxa fonu ağ */
  color: #007bff; /* Textin rəngi mavi */
  font-size: 16px; /* Yazı ölçüsü */
  text-align: center;
  border: 2px solid #007bff; /* Mavi border */
  border-radius: 6px; /* Yumşaq künclər */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 20px auto; /* Auto margin ilə mərkəzləşdiririk */
}

.load-more:hover {
  background-color: #007bff; /* Hover zaman arxa fonu mavi */
  color: #fff; /* Text rəngi ağ olacaq */
}

.load-more:disabled {
  background-color: #f0f0f0; /* Disabled olduqda arxa fonu açıq boz */
  color: #ccc; /* Text rəngi boz olacaq */
  border: 2px solid #ccc; /* Border də boz olacaq */
  cursor: not-allowed; /* Siçan göstəricisi deaktiv olacaq */
}

