/* Global Stil */
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f0f4f8;
}

/* MotoTaksi Form Container */
.moto-taksi-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

/* MotoTaksi Form */
.moto-taksi-form {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 600px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Form Başlığı */
.moto-taksi-title {
    font-size: 32px;
    font-weight: 700;
    color: #333;
    margin-bottom: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: linear-gradient(to right, #4caf50, #45a049);
    /* Gradient background */

    /* Vendor prefix for Safari and older browsers */
    -webkit-background-clip: text;

    /* Standard property */
    background-clip: text;

    /* Ensure text color is transparent to show the gradient */
    color: transparent;
}


/* Sifariş Formu */
.sifarish-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* Input və Textarea */
.input-field {
    width: 100%;
    max-width: 100%;
    padding: 14px;
    margin-bottom: 20px;
    border-radius: 12px;
    border: 1px solid #ddd;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
    color: #333;
    resize: none;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    background-color: #f9f9f9;
}


/* Sifariş Et Düyməsi */
.sifarish-btn {
    margin-top: 20px;
    padding: 14px 32px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #4caf50;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Sifariş Et Düyməsi Hover */
.sifarish-btn:hover {
    background-color: #45a049;
    transform: scale(1.05);
}

/* Formda Hover Effekti */
.moto-taksi-form:hover {
    transform: translateY(-5px);
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.15);
}

/* Qiymət və Razılaşma yolu Mətni */
.display-text {
    color: #0066cc;
    /* Mavi rəng */
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
    margin-top: 20px;
    animation: fadeIn 1s ease-out;
}

/* Animasiya Effekti */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Responsiv Dizayn */
@media (max-width: 768px) {
    .moto-taksi-form {
        padding: 25px;
        width: 100%;
        max-width: 95%;
    }

    .moto-taksi-title {
        font-size: 26px;
    }

    .input-field {
        font-size: 14px;
    }

    .sifarish-btn {
        font-size: 16px;
        padding: 12px 25px;
    }
}