.admin-login-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Üst tərəfdə mərkəzləşdiririk */
  height: 100vh;
  background-color: #f5f5f5;
  padding-top: 50px; /* Yuxarıdan boşluq */
}

.admin-login-box {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.admin-login-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333; /* Daha yaxşı oxunurluq üçün rəng */
}

.admin-login-input {
  width: 100%;
  padding: 12px;
  margin: 12px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box; /* Padding daxilində ölçü */
}

.admin-login-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.admin-login-button:hover {
  background-color: #0056b3;
}

.admin-login-error-message {
  color: red;
  margin-bottom: 15px;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .admin-login-box {
    padding: 25px;
    width: 90%;
  }

  .admin-login-title {
    font-size: 1.75rem;
  }

  .admin-login-button {
    font-size: 1rem;
  }
}
