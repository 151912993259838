.elan-container {
    max-width: 500px;
    margin: 50px auto;
    padding: 25px;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    font-family: 'Segoe UI', sans-serif;
    transition: 0.3s ease;
  }
  
  .elan-container h2 {
    text-align: center;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 24px;
    color: #222;
  }
  
  .elan-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .elan-form input,
  .elan-form textarea,
  .elan-form select {
    padding: 12px 14px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 14px;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
  }
  
  .elan-form input:focus,
  .elan-form textarea:focus,
  .elan-form select:focus {
    border-color: #27ae60;
    outline: none;
    box-shadow: 0 0 0 3px rgba(39, 174, 96, 0.15);
  }
  
  .elan-form textarea {
    font-family: 'Segoe UI', sans-serif;
    resize: vertical;
    min-height: 100px;
  }
  
  .elan-form input[type="file"] {
    display: none;
  }
  
  .file-label {
    display: inline-block;
    padding: 12px 20px;
    background-color: #eeeeee;
    color: #444;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    transition: 0.2s ease;
  }
  
  .file-label:hover {
    background-color: #ddd;
    color: #000;
  }
  
  .image-preview {
    text-align: center;
  }
  
  .image-preview img {
    width: 100%;
    height: 250px;
    object-fit: contain;
    border-radius: 12px;
    margin-top: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .elan-form button {
    padding: 14px;
    background: #27ae60;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background 0.2s ease;
  }
  
  .elan-form button:hover {
    background: #219653;
  }
  
  /* ✅ Mobil uyğunlaşdırma */
  @media (max-width: 600px) {
    .elan-container {
      margin: 0 auto;
      padding: 16px;
      padding-bottom: 120px;
      border-radius: 12px;
      box-shadow: none;
    }
  
    .elan-container h2 {
      font-size: 20px;
      margin-bottom: 16px;
    }
  
    .elan-form input,
    .elan-form textarea,
    .elan-form select {
      font-size: 13px;
      padding: 10px;
    }
  
    .elan-form button {
      font-size: 15px;
    }
  
    .image-preview img {
      height: 200px;
    }
  }
  