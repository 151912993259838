/* Loader modalı üçün stil */
.loader-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-şeffaf fon */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  font-size: 36px; /* Kiçik yazı ölçüsü */
  display: inline-block;
  font-family: 'Raleway', sans-serif; /* Daha estetik font */
  font-weight: bold;
  color: #27AE60; /* Yaşıl rəng */
  letter-spacing: 1px;
  position: relative;
}

.loader::after {
  content: 'MotoTaksi.az';
  position: absolute;
  left: 0;
  top: 0;
  color: #ffffff; /* Sarı rəng */
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  animation: animloader 2s ease-in infinite; /* Daha sürətli animasiya */
}

/* Yükləmə animasiyası */
@keyframes animloader {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
