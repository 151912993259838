/* Ümumi Elan səhifəsi stili */
.elan-container {
  background-color: #f9f9f9;
  min-height: 100%;
}

/* Kategoriya düymələri */
.category-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;  /* Kategoriyalar arasındakı boşluğu kiçiltmək */
}

.category-button {
  background-color: #fff;
  border: 2px solid #007bff;
  padding: 8px 20px; /* Daha kiçik ölçü */
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem; /* Kategoriyaların yazı ölçüsünü kiçiltmək */
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Geri düyməsi üçün CSS */
.back-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #0056b3;
}


.category-button.active {
  background-color: #007bff;
  color: white;
  border: 2px solid #007bff;
}

.category-button:hover {
  background-color: #0056b3;
  color: white;
}

/* Axtarış bölməsi */
.search-section {
  text-align: center;
  margin-bottom: 30px;
}

.search-input {
  width: 100%;
  max-width: 500px;
  padding: 12px;
  font-size: 1rem; /* Axtarış yazısının ölçüsünü kiçiltmək */
  border: 2px solid #ddd;
  border-radius: 25px;
  margin-top: 10px;
  text-align: center;
  outline: none;
  transition: border-color 0.3s;
}

.search-input:focus {
  border-color: #007bff;
}

/* Elanların yerləşməsi */
.elans-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); /* Mobil üçün uyumlu */
  gap: 25px;
  justify-items: center;
}

/* Elan Kartları */
.elan-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;  /* Kartın içini biraz daha az tutmaq */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  max-width: 350px;
  width: 100%;
}

.elan-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.elan-card img {
  max-width: 100%;
  border-radius: 10px;
}

.elan-card .elan-title {
  font-size: 1.2rem; /* Elan başlığını kiçiltmək */
  font-weight: 700;
  color: #333;
  margin: 15px 0;
}

.elan-card .elan-price {
  font-size: 1.1rem; /* Qiymət yazısının ölçüsünü kiçiltmək */
  color: #007bff;
  font-weight: bold;
}

/* Elan tapılmadıqda mesaj */
.no-elans {
  text-align: center;
  font-size: 1.3rem; /* Mesajın ölçüsünü kiçiltmək */
  color: #888;
  font-weight: 600;
}

/* Responsivlik üçün media query */
@media (max-width: 1024px) {
  .category-button {
    font-size: 0.95rem; /* Kategoriya düymələrinin font ölçüsü kiçildilir */
    padding: 8px 15px;
  }

  .search-input {
    max-width: 90%;
  }

  .elan-card {
    padding: 18px;
    max-width: 320px;
  }
}

@media (max-width: 768px) {
  .category-buttons {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .category-button {
    font-size: 0.9rem;
    padding: 8px 15px;
    width: 90%;
  }

  .search-input {
    max-width: 85%;
    font-size: 0.95rem;
  }

  .elan-card {
    max-width: 100%;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .category-button {
    font-size: 0.85rem;
    padding: 6px 12px;
  }

  .search-input {
    max-width: 80%;
    font-size: 0.9rem;
  }

  .elan-card {
    max-width: 100%;
    padding: 12px;
  }
}


.elans-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-bottom: 50px;
}

/* İstifadəçi adı */
.elan-user-name {
  font-size: 1rem;
  color: #555;
  margin-top: 10px;
  font-style: italic;
  text-align: center;
  color: #888;
}
