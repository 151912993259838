.chat-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
}

.chat-box {
  background: #ffffff;
  width: 100%;
  max-width: 600px;
  height: 85%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  /* Mobil cihazlarda daha küçük margin ve padding ekledik */
  margin: 0 10px;
}

.whatsapp-join-btn-container {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f1fdf5;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whatsapp-join-btn {
  display: inline-block;
  background-color: #25D366;
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding: 12px 24px;
  font-size: 14px;
  border-radius: 8px;
  text-decoration: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.whatsapp-join-btn:hover {
  background-color: #1ebe5b;
  transform: scale(1.03);
}

.whatsapp-join-btn:active {
  transform: scale(0.97);
}


@media (max-width: 768px) {
  .chat-box {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    /* Mobilde taşma olmasın diye margin kaldırıldı */
  }

  .chat-header {
    padding: 12px 16px;
    /* Header padding küçültüldü */
  }

  .chat-title {
    font-size: 14px;
    /* Başlık font boyutu küçültüldü */
  }

  .send-btn {
    font-size: 14px;
    /* Gönder butonunun font boyutu küçültüldü */
    padding: 0 12px;
  }
}

.chat-header {
  background: #f7f7f7;
  padding: 14px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.chat-back {
  display: none;
  font-size: 18px;
  cursor: pointer;
}

.chat-title {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.chat-close {
  font-size: 18px;
  cursor: pointer;
  color: #444;
}

@media (max-width: 768px) {
  .chat-back {
    display: inline-block;
  }
}

.chat-body {
  flex: 1;
  padding: 16px;
  background: #f6f6f6;
  overflow-y: auto;
  padding-bottom: 10px;
}

.chat-date-group {
  margin-bottom: 20px;
}

.chat-date-title {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #888;
}

.chat-message {
  background: #e8f0fe;
  padding: 12px 16px;
  border-radius: 10px;
  margin-bottom: 12px;
  color: #333;
  font-size: 14px;
  max-width: 90%;
  word-wrap: break-word;
  word-break: break-word;
  /* Kelimeler taşarsa düzgün kırılır */
}

.chat-message strong {
  display: block;
  margin-bottom: 4px;
  color: #1e88e5;
}

.chat-message small {
  display: block;
  margin-top: 6px;
  font-size: 12px;
  color: #888;
  text-align: right;
}

.chat-input {
  display: flex;
  padding: 12px 16px;
  background: #fafafa;
  border-top: 1px solid #ddd;
}

.chat-input input {
  flex: 1;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s;
}

.chat-input input:focus {
  border-color: #1e88e5;
}

.send-btn {
  border: none;
  background: #1e88e5;
  color: white;
  font-weight: bold;
  border-radius: 8px;
  padding: 0 16px;
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.send-btn:hover {
  background: #1565c0;
}

.chat-message.admin-message {
  background: #d1e7dd;
  /* Admin mesajı üçün fərqli fon rəngi */
  color: #155724;
  /* Admin mesajının mətn rəngi */
  border-left: 4px solid #198754;
}

/* Mobilde daha rahat kullanım için ek padding ve font boyutu */
@media (max-width: 768px) {
  .chat-message {
    font-size: 12px;
    padding: 10px 14px;
  }

  .send-btn {
    font-size: 14px;
    padding: 0 12px;
  }

  .chat-body {
    padding: 12px 10px;
  }
}

@media (max-width: 480px) {
  .whatsapp-join-btn {
    width: 90%; /* Telefonda tam ortalanmış geniş düymə */
    font-size: 15px;
    padding: 10px;
  }
}
