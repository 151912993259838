.contact-container {
    padding: 40px 20px;
    background: #f9f9f9;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-info-page {
    background: #ffffff;
    padding: 30px 40px;
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
    max-width: 600px;
    width: 100%;
    text-align: center;
}

.contact-info-page h2 {
    font-size: 28px;
    color: #1e88e5;
    margin-bottom: 15px;
}

.contact-info-page p {
    font-size: 16px;
    color: #555;
    margin-bottom: 30px;
}

.contact-item {
    font-size: 16px;
    margin: 12px 0;
    color: #333;
}

.contact-item a {
    color: #1e88e5;
    text-decoration: none;
    transition: 0.3s ease;
}

.contact-item a:hover {
    text-decoration: underline;
}

/* Əlavə əlaqə linkləri (düymə formasında) */
.contact-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    margin-top: 30px;
}

.contact-links a {
    background-color: #1e88e5;
    color: #fff;
    padding: 10px 18px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    transition: background 0.3s ease, transform 0.2s ease;
}

.contact-links a:hover {
    background-color: #1565c0;
    transform: scale(1.05);
}

/* Mobil uyğunluq */
@media (max-width: 480px) {
    .contact-info-page {
        padding: 20px;
    }

    .contact-info-page h2 {
        font-size: 22px;
    }

    .contact-info-page p,
    .contact-item {
        font-size: 14px;
    }

    .contact-links {
        flex-direction: column;
        gap: 10px;
    }

    .contact-links a {
        font-size: 15px;
    }
}