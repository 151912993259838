.chat-overlay {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow: hidden;
  }
  
  .chat-overlay::before {
    content: "";
    position: absolute;
    inset: 0;
    background: url('https://images.unsplash.com/photo-1615172282427-9a57ef2d142e?q=80&w=3006&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') center center / cover no-repeat;
    filter: blur(8px);
    transform: scale(1.05); /* blur kənarlarını gizlətmək üçün */
    z-index: -1;
  }
  
  .chat-box {
    background: rgba(255, 255, 255, 0.95);
    width: 100%;
    max-width: 600px;
    height: 85%;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  }
  
  .chat-header {
    background: white;
    padding: 14px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
  }
  
  .chat-back {
    display: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .chat-title {
    font-weight: bold;
    font-size: 16px;
  }
  
  .chat-close {
    font-size: 18px;
    cursor: pointer;
    color: #444;
  }
  
  @media (max-width: 768px) {
    .chat-back {
      display: inline-block;
    }
  
    .chat-box {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
    }
  }
  
  .chat-body {
    flex: 1;
    padding: 16px;
    background: #f6f6f6;
    overflow-y: auto;
  }
  
  .chat-date-group {
    margin-bottom: 20px;
  }
  
  .chat-date-title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
  }
  
  .chat-message {
    position: relative; /* Sil ikonunu yerləşdirmək üçün relative konteyner */
    background: #e8f0fe;
    padding: 12px 16px;
    border-radius: 10px;
    margin-bottom: 12px;
    color: #333;
    font-size: 14px;
  }
  
  .chat-message.admin-message {
    background-color: #dff0d8; /* Admin mesajları üçün fərqli rəng */
    border-left: 5px solid #28a745;
  }
  
  .chat-message strong {
    font-weight: bold;
  }
  
  .chat-input {
    display: flex;
    padding: 12px 16px;
    background: #fafafa;
    border-top: 1px solid #ddd;
  }
  
  .chat-input input {
    flex: 1;
    padding: 10px 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 14px;
    outline: none;
  }
  
  .send-btn {
    border: none;
    background: #1e88e5;
    color: white;
    font-weight: bold;
    border-radius: 8px;
    padding: 0 16px;
    margin-left: 10px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .send-btn:hover {
    background: #1565c0;
  }
  
  .chat-message small {
    display: block;
    text-align: right;
    font-size: 12px;
    color: #888;
    margin-top: 5px;
  }
  
  .chat-message .username-link {
    cursor: pointer;
    color: #1e88e5;
    text-decoration: underline;
  }
  
  .chat-message .username-link:hover {
    color: #1565c0;
  }
  
  .chat-back,
  .chat-close {
    cursor: pointer;
  }
  
  .username-link {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .username-link:hover {
    color: #0056b3;
  }
  
  .delete-icon {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 16px;
    color: #dc3545;
    cursor: pointer;
    transition: transform 0.2s ease, color 0.3s ease;
  }
  
  .delete-icon:hover {
    color: #c82333;
    transform: scale(1.2);
  }
  
  .delete-icon:active {
    transform: scale(0.9);
  }
  