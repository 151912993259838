.about-container {
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    background-color: #f9f9f9;
    min-height: 80vh;
}

.about-box {
    background-color: #fff;
    padding: 30px 40px;
    max-width: 800px;
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.about-title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #1e88e5;
    text-align: center;
}

.about-text {
    font-size: 16px;
    line-height: 1.8;
    color: #333;
    margin-bottom: 18px;
    text-align: justify;
}

/* Responsive */
@media (max-width: 768px) {
    .about-box {
        padding: 20px;
    }

    .about-title {
        font-size: 22px;
    }

    .about-text {
        font-size: 15px;
    }
}

@media (max-width: 480px) {
    .about-title {
        font-size: 20px;
    }

    .about-text {
        font-size: 14px;
    }
}