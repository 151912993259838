.elan-container {
  max-width: 1100px;
  margin: 30px auto;
  padding: 20px;
  padding-bottom: 120px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.elan-header {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.elan-image {
  width: 400px;
  height: auto;

  border-radius: 10px;
  object-fit: contain;
}

.elan-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.elan-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.elan-price {
  font-size: 22px;
  color: #e84c3d;
  font-weight: bold;
  margin-bottom: 15px;
}

.elan-meta {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.premium-badge {
  background-color: gold;
  color: black;
  font-weight: bold;
  padding: 2px 8px;
  border-radius: 4px;
  margin-left: 10px;
}

.elan-body {
  margin: 15px 0;
  line-height: 1.5;
}

.contact-info {
  background-color: #f5f5f5;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.contact-person span {
  display: block;
  margin-bottom: 5px;
}

.elan-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 10px;
}

.elan-actions a,
.elan-actions button {
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  transition: 0.3s;
}

.boost-btn {
  background-color: #ff9800;
  color: white;
}

.boost-btn:hover {
  background-color: #e68900;
}

.call-btn {
  background-color: #4caf50;
  color: white;
}

.call-btn:hover {
  background-color: #3d8b40;
}

.whatsapp-btn {
  background-color: #25d366;
  color: white;
}

.whatsapp-btn:hover {
  background-color: #1ebe58;
}

@media (max-width: 768px) {
  .elan-header {
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
  }

  .elan-image {
    width: 100%;
    max-width: 320px;
    height: auto;
    border-radius: 8px;
    display: block;
    margin: 0 auto;
  }

  .elan-info {
    padding: 0 10px;
  }

  .elan-title {
    font-size: 20px;
    text-align: center;
  }

  .elan-price {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
  }

  .elan-meta {
    text-align: center;
    font-size: 13px;
  }

  .elan-body {
    font-size: 14px;
    text-align: justify;
  }

  .contact-info {
    padding: 10px;
    font-size: 14px;
    text-align: center;
  }

  .contact-person {
    display: flex;
    flex-direction: column;
    align-items: center;
    word-wrap: break-word;
    word-break: break-word;
  }

  .contact-person span {
    text-align: center;
    max-width: 100%;
    overflow-wrap: break-word;
  }
  
  .elan-actions {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .elan-actions a,
  .elan-actions button {
    width: 100%;
    font-size: 15px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
  }
}

.owner-actions {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.edit-btn,
.delete-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
}

.edit-btn {
  background-color: #007bff;
  color: white;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
}

/* Geri düyməsi üçün CSS */
.back-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #0056b3;
}


.clickable-username {
  color: #2563eb;
  cursor: pointer;
  font-weight: 500;
  transition: color 0.2s ease;
}

.clickable-username:hover {
  color: #1d4ed8;
  text-decoration: underline;
}
