.card {
    max-width: 200px;
    border-radius: 12px;
    cursor: pointer;
    overflow: hidden;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    font-family: sans-serif;
    transition: transform 0.3s ease;
    flex-shrink: 0;
}

.card:hover {
    transform: translateY(-4px);
}


/* Mövcud .card üstündəki kod eyni qalır */

.card.premium {
  border: 2px solid gold;
  box-shadow: 0 4px 12px rgba(255, 215, 0, 0.4);
  position: relative;
}

.premium-badge {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: gold;
  color: black;
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 6px;
  font-weight: bold;
}



.card-image {
    position: relative;
    height: 130px;
    overflow: hidden;
    border-radius: 12px 12px 0 0;
}

.card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.photo-count {
    position: absolute;
    bottom: 5px;
    left: 5px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 2px 6px;
    border-radius: 8px;
    font-size: 12px;
}

.card-info {
    padding: 10px;
}

.price {
    font-weight: bold;
    font-size: 16px;
}

.title {
    font-weight: 600;
    font-size: 14px;
    margin-top: 4px;
}

.desc {
    font-size: 13px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.meta {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 12px;
    color: gray;
}

.card-username {
    font-size: 1rem;
    color: #007bff; /* Username mavi rənglə olacaq */
    margin-top: 10px;
    font-weight: bold;
    background-color: #f0f8ff; /* Arxa fon rəngi */
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .card-username:hover {
    background-color: #0056b3; /* Hover zamanı fon rəngini dəyişirik */
    color: white; /* Hover zamanı yazı rəngi ağ olacaq */
  }
  
  

/* 🔁 Responsive */
@media (max-width: 768px) {
    .card {
        width: calc(50% - 20px); /* 10px hər kartın sağ və sol boşluğuna uyğun gələcək */
        min-width: 110px;
    }
      
    .price {
        font-size: 18px;
    }

    .title {
        font-size: 16px;
    }

    .desc {
        font-size: 14px;
        white-space: normal;
    }

    .meta {
        flex-direction: column;
        gap: 4px;
    }
}