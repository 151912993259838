/* Dashboard stilinin bir hissəsi */
.dashboard-container {
  padding: 30px;
  background-color: #f4f4f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Dashboard başlığı */
.dashboard-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 40px;
  color: #333;
  font-weight: bold;
  letter-spacing: 1px;
}

/* Çıxış düyməsi */
.logout-button {
  margin-top: 30px;
  background-color: #ff4d4d; /* Kırmızı */
  color: white;
  padding: 12px 20px;
  font-size: 1.2rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #e03e3e;
}

/* Kartların yerləşməsi */
.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)); /* Kartları ekran ölçüsünə uyğun doldurur */
  gap: 20px;
  justify-items: center; /* Kartları ortalayır */
  width: 100%; /* Kartlar daha geniş göstərilsin */
  max-width: 1200px; /* Max genişlik */
  margin: 0 auto;
}

/* Hər bir kartın üslubu */
.dashboard-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px; /* Daha kiçik padding */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer; /* Mouse hover zamanı pointer effekti */
  width: 100%;
  max-width: 350px;
  box-sizing: border-box;
}

.dashboard-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Kart başlığı üslubu */
.card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.card-header i {
  font-size: 24px; /* Font ölçüsü kiçildildi */
  margin-right: 10px;
  color: #007bff;
}

.card-header span {
  font-size: 1.2rem; /* Font ölçüsü kiçildildi */
  font-weight: 600;
  color: #333;
}

/* Kartın içeriği */
.card-content {
  font-size: 1rem; /* Font ölçüsü kiçildildi */
  color: #555;
  margin-bottom: 10px;
}

/* Kartlarda düymə və ya digər əlavə məzmun əlavə etmək üçün */
.card-footer {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-footer button {
  background-color: #007bff;
  color: white;
  padding: 8px 12px; /* Kiçik padding */
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 0.95rem; /* Kiçik font ölçüsü */
  transition: background-color 0.3s ease;
}

.card-footer button:hover {
  background-color: #0056b3;
}

/* Responsivlik üçün media query */
@media (max-width: 768px) {
  .dashboard-title {
    font-size: 1.8rem; /* Font ölçüsü kiçildildi */
  }

  .card-header i {
    font-size: 22px; /* Font ölçüsü kiçildildi */
  }

  .card-content {
    font-size: 0.95rem; /* Font ölçüsü kiçildildi */
  }

  .dashboard-cards {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)); /* Mobil cihazlarda daha kiçik kartlar */
    gap: 15px;
  }

  .dashboard-card {
    padding: 15px; /* Kiçik padding */
    width: 100%;
    max-width: 320px;
  }

  .card-footer button {
    font-size: 0.85rem;
    padding: 6px 10px;
  }
}

@media (max-width: 480px) {
  .dashboard-title {
    font-size: 1.6rem; /* Font ölçüsü kiçildildi */
  }

  .card-header i {
    font-size: 20px; /* Font ölçüsü kiçildildi */
  }

  .dashboard-card {
    padding: 12px; /* Kiçik padding */
    width: 100%;
    max-width: 290px;
  }

  .card-footer button {
    font-size: 0.8rem;
    padding: 6px 10px;
  }
}
